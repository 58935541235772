"use client";
import React, { useEffect, useState } from "react";
import { apiConfig } from "../../../services/apiConfig";
import axios from "axios";
import * as BsIcons from "react-icons/bs";
import { NavLink } from "react-router-dom";
import "./ParametrosWapi.css";

const ParametrosWapi = ({}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [formData, setFormData] = useState({
    instances: "",
    tokenWs: "",
     id_business_unit: user.id_business_unit,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiConfig + "wapi/getWapi.php?id_business_unit="+user.id_business_unit);
        if (response.data) {
          setFormData(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [user.id_business_unit]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(apiConfig + "wapi/saveWapi.php", formData);
      alert("Data saved successfully");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <div className="container ">
      <div className="text-start">
        <NavLink exact to={"/config"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#736CED" }}>
        <div className="card-body">
          <div className="text-center text-white">Integración wapi para envios mensajes</div>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="pt-5">
        <div className="mb-3">
          <label htmlFor="instances" className="form-label">
            Instances
          </label>
          <input
            type="text"
            className="form-control"
            id="instances"
            name="instances"
            value={formData.instances}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="tokenWs" className="form-label">
            Token
          </label>
          <input
            type="text"
            className="form-control"
            id="tokenWs"
            name="tokenWs"
            value={formData.tokenWs}
            onChange={handleChange}
          />
        </div>
		<br/>
        <button type="submit" className="btn btn-primary">
          Guardar
        </button>
      </form>
    </div>
  );
};

export default ParametrosWapi;

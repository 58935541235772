import React, { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import axios from "axios";
import { apiConfig } from "../../services/apiConfig";
import moment from "moment";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const Mensajeria = () => {
 
  const day = moment().format("YYYY-MM-DD");
  const [turnos, setTurnos] = useState(null);
  const [locales, setLocales] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [desde, setDesde] = useState(day);
  const [hasta, setHasta] = useState(day);
  const [local, setLocal] = useState("");
  const [estadoTurno, setEstadoTurno] = useState("");
  const [buttonActive, setButtonActive] = useState(false);
  const [buttonText, setButtonText] = useState("Recordatorio Turno");
  const [hideButton, setHideButton] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [formData, setFormData] = useState({
    instances: "",
    tokenWs: "",
     id_business_unit: user.id_business_unit,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiConfig + "wapi/getWapi.php?id_business_unit="+user.id_business_unit);
        if (response.data) {
          setFormData(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [user.id_business_unit]);

  const [mensaje, setMensaje] = useState(
    "Hola, como estas? Quiero recordarte tu turno para TRATAMIENTO el DIA a las HORA. Por favor, debes confirmar el turno en el siguiente ENLACE. Gracias!"
  );

  useEffect(() => {
    const getData = () => {
      axios
        .get(apiConfig + "business/list/?id=" + Number(user.id_business_unit))
        .then((getData) => {
          setLocales(getData.data.data);
        });
    };
    getData();
  }, []);

  const buscarTurnos = () => {
    if (desde && hasta && local && estadoTurno) {
      setButtonActive(false);
      setButtonText("Recordatorio Turno");

      setHideButton(false);
      axios
        .get(apiConfig + "mensajeria/list/", {
          params: {
            id: local,
            desde: desde,
            hasta: hasta,
            id_estado: Number(estadoTurno),
          },
        })
        .then((response) => {
          let res = response.data.data;

          if (res.length > 0) {
            setTurnos(res);
          } else {
            window.alert("No se encontraron registros");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.alert("Debes completar los campos");
    }
  };

  const colorMap = {
    1: "#ffba57", // "solicitado"
    2: "#39afd1", // "reservado"
    3: "#3b82f6", // "confirmado"
    4: "#fa5c7c", // "suspendido"
    5: "#9e9e9e", // "no asistió"
    6: "#0acf97", // "finalizado"
  };

  async function notificar(a) {
    setButtonActive(true);
    setButtonText("");

    setTimeout(() => {
      setHideButton(true);
      // document.body.style.backgroundColor = '#00a650';
    }, 8000);

    for (const registro of a) {
      const phoneNumber = registro.telefono;
      const message = `Hola, como estas? Quiero recordarte tu turno para ${
        registro.tratamiento
      } el dia ${moment(registro.fecha).format("dddd D/MM/YYYY")} a las ${
        registro.hora
      }. Por favor, debes confirmar el turno en el siguiente enlace ${apiConfig}back/pay/?id=${
        registro.codigo
      }. Gracias!`;

      try {
        const options = {
          method: "POST",
          url:
            "https://waapi.app/api/v1/instances/" +
            formData.instances +
            "/client/action/send-message",
          headers: {
            accept: "application/json",
            "content-type": "application/json",
            Authorization: "Bearer " + formData.tokenWs,
          },
          data: {
            chatId: "549" + phoneNumber + "@c.us",
            message: message,
          },
        };

        await axios
          .request(options)
          .then(function (response) {
            console.log(response.data);
          })
          .catch(function (error) {
            console.error(error);
          });
      } catch (error) {
        console.error("Error al enviar el mensaje de WhatsApp:", error);
      }
    }
  }

  useEffect(() => {
    setButtonActive(false);
    setButtonText("Enviar recordatorio");
    setHideButton(false);
  }, [filtro]);

  function viewBuscador() {
    setTurnos(null);
    setLocal(null);
    setEstadoTurno(null);
  }

  return (
    <Container>
      {!turnos ? (
        <>
          <div className="text-start">
            <NavLink exact to={"/calendario"}>
              <h1 className="display-1">
                <BsIcons.BsArrowLeftShort className="app-color" />
              </h1>
            </NavLink>
          </div>
          <div className="card shadow mb-3" style={{ background: "#736CED" }}>
            <div className="card-body">
              <div className="text-center text-white">Mensajería</div>
            </div>
          </div>
          <Row>
            <Col md={6} sm={12}>
              <FloatingLabel
                controlId="floatingActual"
                label="Local *"
                className="mb-3"
              >
                <select
                  className="form-select"
                  name="id_business_office"
                  onChange={(e) => setLocal(e.target.value)}
                  required
                >
                  <option value=""></option>
                  {locales &&
                    locales.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))}
                </select>
              </FloatingLabel>
            </Col>
            <Col md={6} sm={12}>
              <FloatingLabel
                controlId="floatingEstado"
                label="Estado del turno *"
                className="mb-3"
              >
                <Form.Select
                  name="id_estado"
                  aria-label="Estado del turno *"
                  type="text"
                  onChange={(e) => setEstadoTurno(e.target.value)}
                  required
                >
                  <option></option>
                  <option value="2">Pendiente</option>
                  <option value="3">Confirmado</option>
                  <option value="0">Todo</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col md={6} sm={12}>
              <FloatingLabel
                controlId="floatingFecha"
                label="Desde *"
                className="mb-3"
              >
                <Form.Control
                  type="date"
                  name="desde"
                  min={day}
                  value={desde && desde}
                  onChange={(e) => setDesde(e.target.value)}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md={6} sm={12}>
              <FloatingLabel
                controlId="floatingFecha"
                label="Hasta *"
                className="mb-3"
              >
                <Form.Control
                  type="date"
                  name="hasta"
                  min={desde}
                  value={hasta && hasta}
                  onChange={(e) => setHasta(e.target.value)}
                  required
                />
              </FloatingLabel>
            </Col>

            <div className="d-grid gap-2 pt-5 mb-5 col-6 mx-auto">
              <Button variant="primary" onClick={buscarTurnos}>
                Buscar
              </Button>
            </div>
          </Row>
        </>
      ) : (
        <>
          <div className="text-start">
            <h1 className="display-1">
              <BsIcons.BsArrowLeftShort
                className="app-color"
                onClick={() => viewBuscador()}
              />
            </h1>
          </div>
          <div>
            {locales &&
              locales
                .filter((x) => x.id === local)
                .map((data, index) => (
                  <p key={index}>
                    {data.name} del {moment(desde).format("DD/MM/YYYY")} Al{" "}
                    {moment(hasta).format("DD/MM/YYYY")}
                  </p>
                ))}
          </div>
          <div className="pt-5">
            <div className="input-group ">
              <input
                placeholder="Filtro"
                className="form-control form-control-sm"
                type="text"
                onChange={(e) => setFiltro(e.target.value)}
              />
              <span className="input-group-text" id="basic-addon2">
                <BsIcons.BsSearch />
              </span>
            </div>
          </div>
          <br />
          <div className="row mb-5">
            <div className="col-md-6 col-sm-12">
              {turnos &&
                turnos
                  .filter(
                    (datos) =>
                      datos.codigo
                        .toLocaleLowerCase()
                        .includes(filtro.toLocaleLowerCase()) ||
                      datos.tratamiento
                        .toLocaleLowerCase()
                        .includes(filtro.toLocaleLowerCase()) ||
                      datos.estado_pago
                        .toLocaleLowerCase()
                        .includes(filtro.toLocaleLowerCase()) ||
                      datos.fecha
                        .toLocaleLowerCase()
                        .includes(filtro.toLocaleLowerCase())
                  )
                  .map((a, i) => (
                    <div
                      className="card shadow mb-3"
                      key={i}
                      style={{
                        background: colorMap[Number(a.id_estado)] || "green",
                        color: "white",
                      }}
                    >
                      <div className="card-body">
                        <Row>
                          <div className="col text-start">
                            {a.tratamiento}
                            <br />
                            <small>
                              <span>
                                #{a.codigo}{" "}
                                {moment(a.fecha).format("DD-MM-YYYY")} {a.hora}
                              </span>
                            </small>
                          </div>
                          <div className="col-4 text-end">
                            <small>Estado turno</small>
                            <br />
                            <small>
                              <span>{a.estado}</span>
                            </small>
                          </div>
                        </Row>
                      </div>
                    </div>
                  ))}
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="mb-3 text-start">Modelo de textos a enviar</div>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Enviar mensaje"
                  id="floatingTextarea2"
                  style={{ height: 100 }}
                  value={mensaje && mensaje}
                  onChange={(e) => setMensaje(e.target.value)}
                  disabled
                />
                <label htmlFor="floatingTextarea2">Enviar mensaje</label>
                <small>
                  No puedes modificar el texto del mensaje , solo version Pro.
                </small>
              </div>
              <div className="text-end mb-3">
                {!hideButton ? (
                  <button
                    id="color-button"
                    className={buttonActive ? "active" : ""}
                    onClick={() => notificar(turnos)}
                  >
                    {buttonText}
                  </button>
                ) : (
                  <button id="color-button-exito">
                    <BsIcons.BsSendCheck />
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};
export default Mensajeria;
